import React from "react";
import BackToTop from "../Components/backToTop";
import Between_Content from "../Components/ads/BetweenContent";
import Parallax_Code from "../Components/ads/ParallaxCode";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";

const LifeStyleView = () => {
  return (
    <div>
      <div className="container max-w-7xl mx-auto px-4">
        <div className="">
          <h3
            className="lg:max-w-[50%] mx-auto text-[40px] text-center mt-10 mb-5 font-semibold"
            style={{
              fontFamily: "Playfair Display",
              fontSize: "clamp(1.5rem, 4vw, 3rem)",
            }}
          >
             Hair Trends that Must-Try According to Expert
          </h3>
          <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/trendImg.webp" alt="" />
          <Between_Content />
          <div
            className="lg:w-[60%] mx-auto w-full"
            style={{ fontFamily: "Playfair Display" }}
          >
            <p className="mt-4 md:text-[20px] text-[17px]">
              Hair is a deeply personal experience. It’s a part of your identity
              and how you present yourself to the world, and the slightest
              change can either make you feel euphoric or send you spiraling
              (who hasn’t cried because of a bad haircut or coloring job?).
              Entrusting a stylist to deliver your desired results is a gamble,
              equally as risky as trusting yourself to find a hairstyle you'd
              feel confident in. Enter: ELLE.com's guide to the best hair trends
              of 2024.
            </p>
            <p className="mt-4 md:text-[20px] text-[17px]">
              We're in the throes of a new year, so what better time than now to
              switch up your signature look? In the past year, TikTok users have
              spawned a barrage of hair trends, from Sophie Richie-inspired buns
              to '70s-style fringe curtain bangs. As we look towards 2024, there
              are a variety of trends to look out for, so we compiled a guide to
              six of the best ones to make your new signature style.{" "}
            </p>
            <p className="mt-4 md:text-[20px] text-[17px]">
              In order to gather our top six hair trends of 2024, we observed
              product releases, watched the runways and red carpets, and
              consulted seven hair stylists and experts who know their way
              around a salon chair. Maximalists, minimalists, and everyone in
              between are sure to find a trend they connect with. Who knows?
              Maybe you'll want to add volume to your hair with hair
              extensions or bleach your hair, too. The possibilities, as always,
              are endless.
            </p>
          </div>
          <Parallax_Code />
          <div className="">
            <h3
              className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
              }}
            >
              Volume, Volume, Volume
            </h3>
            <div className="lg:w-[60%] mx-auto w-full flex justify-center">
              <div>
                <div className="flex justify-center">
                  <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/image+123.webp" alt="" className="" />
                </div>

                <p className="mt-4 md:text-[18px] text-[17px]">
                  “With the mob wife aesthetic continuing to be popular, I think
                  voluminous styles will stay a huge trend for 2024. I see big
                  bouncy blowouts and intentionally messy updos continuing to be
                  popular this year,” says Laura Polko, a celebrity hairstylist
                  based in Los Angeles.
                </p>
                <p className="mt-4 md:text-[20px] text-[17px]">
                  If 2023 was about tight, slicked-back buns, 2024 is the year
                  of letting it all loose. Justine Marjan, a celebrity
                  hairstylist based in Los Angeles, also predicts “layered cuts
                  with lots of volume. We see many more bouncy blowouts and
                  naturally looking voluminous curls that need a soft layered
                  cut to support the added texture and root lift.” If you have
                  fine hair that resists teasing, Marjan recommends extensions
                  like the ones from Great Lengths to add some fullness. Just
                  make sure to lock in any voluminous style with plenty of
                  hairspray to keep it all in place.
                </p>
              </div>
            </div>
          </div>
          <Below_Featured_Images />
          <div className="">
            <h3
              className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
              }}
            >
              Au Natural
            </h3>
            <div className="lg:w-[60%] mx-auto w-full flex justify-center">
              <div>
                <div className="flex justify-center">
                  <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/au-Natural.webp" alt="" className="" />
                </div>

                <p className="mt-4 md:text-[18px] text-[17px]">
                  If you’re tired of regular salon visits and your hair is
                  begging for a break, this is set to be your year. According to
                  Cody Renegar, a celebrity hairstylist who splits his time
                  between Los Angeles, Washington, D.C., and Arkansas, the
                  coming months will be all about falling in love with the hair
                  color and texture your mama gave you.
                </p>
                <p className="mt-4 md:text-[20px] text-[17px]">
                  “Au naturale, classic looks, but with a personal edge, will be
                  trending in 2024. I see people returning to their roots—pun
                  intended. People are really leaning into the low-maintenance
                  thing right now,” he says. So whether you’re letting your
                  roots grow in or learning to work with your curly hair, you
                  can trust that you’re right on trend. “I'm seeing more and
                  more wanting to embrace their natural texture. If you have a
                  great haircut, all you need is a little product to
                  assist—whether to de-frizz or encourage curls—and you should
                  be able to have a beautiful air-dry,” Kelly Macedo, a stylist
                  and the owner of Interior Hair in Los Angeles, explains.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default LifeStyleView;
