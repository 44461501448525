import React from 'react'

const SubHeadline = ({headlineData}) => {
  return (
    <div className="max-[1100px]:px-3 p-4 pt-0 h-full">
    {headlineData?.map((line, index) => {
      return (
        <div key={index}>
            <div className="flex items-center max-[1100px]:mt-4 mt-5 gap-4 cursor-pointer"
              onClick={() => {
                const formattedTitle = line?.title?.replace(
                  /\s+/g,
                  "-"
                );
                window.location.href = `/headline/${formattedTitle}`;
              }}
            >
              <div className="h-28 w-36">
                <img
                  src={line?.image}
                  alt={line?.title}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="w-52">
                {line?.created_at && (
                  <p className="text-[14px] text-gray-500 max-[1100px]:text-[12px]">
                    {new Date(line?.created_at).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                )}
                <h4 className="line-clamp-3 tracking-[0.3px] font-medium hover:text-blue-800 text-[16px] max-[1100px]:text-[14px]">
                  {line?.title}
                </h4>
              </div>
            </div>
        </div>
      );
    })}
  </div>
  )
}

export default SubHeadline