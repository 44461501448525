import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Base_Url from "../api/baseUrl";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import TopEarner from "../Components/Top-earner";
import CelebrityEvents from "./Celebrity-events";
import BlogPage from "./Blog-page";
import ViewFinancialIdea from "../Components/view financial-idea";
import Parallax_Code from "../Components/ads/ParallaxCode";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";
import Between_Content from "../Components/ads/BetweenContent";
import End_of_Article from "../Components/ads/EndOfArticle";

const Millionaires = () => {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [ideaLoading, setIdeaLoading] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [billionaireData, setBilionaireData] = useState([]);
  const [adDisplayed, setAdDisplayed] = useState(false);
  const limit = 9;

  const navigate = useNavigate();

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 640);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    const allBillionaireData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_Url}api/get-billionaire?page=${page + 1}&limit=${limit}`);
        setLoading(false);
        if (response) {
          setBilionaireData(response?.data?.data);
          setPageCount(response?.data?.totalPage);
        }
      } catch (error) {
        console.log("Error fetching billionaire data:", error);
      }
    };
    allBillionaireData();
  }, [page, limit]);

  const handleCardClick = (formattedTitle) => {
    let clickCount = localStorage.getItem("clickCount") || 0;
    let click = Number(clickCount);
    localStorage.setItem("clickCount", click + 1);
    if (clickCount === 3) {
      setAdDisplayed(true);
    }
    if (clickCount >= 3) {
      localStorage.setItem("clickCount", "0");
    }
    navigate(`/millionaire/${formattedTitle}`);
  };

  const handlePageChange = debounce((e) => {
    setPage(e.selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 300);

  const billionaireList = useMemo(() => {
    return billionaireData?.map((x, index) => (
      <div key={index}>
        <div
          className="border border-black p-2 h-fit cursor-pointer"
          onClick={() => handleCardClick(x?.name?.replace(/\s+/g, "-"))}
        >
          <div className="h-[280px]">
            <img
              src={x?.image}
              loading="lazy"
              alt={x?.name}
              className="h-full w-full object-cover"
            />
          </div>
          <div className="text-center">
            <h3
              className="md:text-[28px] text-[22px] font-bold mt-3 lg:px-3 line-clamp-1"
              style={{ fontFamily: "Playfair Display" }}
            >
              {x?.name}
            </h3>
            {x?.net_worth && (
              <p className="md:text-[18px] text-[16px] font-bold mt-2">
                Net Worth : {x?.net_worth}
              </p>
            )}
            <p
              className="lg:max-w-[90%] mx-auto mt-2 md:text-[16px] text-[14px] tracking-[0.2px] line-clamp-2"
              dangerouslySetInnerHTML={{ __html: x?.description }}
            ></p>
            {x?.updated_at && (
              <p className="text-[14px] text-gray-500 mt-2">
                Updated on:{" "}
                {new Date(x?.updated_at).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            )}
          </div>
        </div>
        {index === 1 && <Below_Featured_Images />}
        {index === 4 && <Between_Content />}
      </div>
    ));
  }, [billionaireData]);

  return (
    <>
      <div className="px-4">
        <div className="container max-w-[1400px] mx-auto pt-[2rem] lg:px-0">
          {loading && blogLoading && ideaLoading ? (
            <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
              <div className="loader2 my-5"></div>
            </div>
          ) : (
            <>
              <div className="">
                <h3
                  className="text-[38 px] text-center font-semibold"
                  style={{
                    fontFamily: "Playfair Display",
                    fontSize: "clamp(1.7rem, 4vw, 2.8rem)",
                  }}
                >
                  Unlocking Millionaire Secrets
                </h3>
                <div className="flex justify-center my-3">
                  <img
                    src="https://billionaireweb.s3.eu-north-1.amazonaws.com/Layer_1.webp"
                    alt=""
                    style={{ width: "clamp(150px, 50%, 250px)" }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 mt-9">
                {billionaireList}
              </div>
              <div className="flex justify-center mt-[33px] max-[768px]:mt-[10px]">
                {!loading && billionaireData?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={isSmallScreen ? 1 : 2}
                    pageRangeDisplayed={isSmallScreen ? 1 : 3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={page}
                  />
                )}
              </div>
              <Parallax_Code />
              <div className="my-5">
                <TopEarner />
              </div>
              <End_of_Article />
              <div className="my-5">
                <CelebrityEvents />
              </div>
              <End_of_Article />
              <div className="mt-5">
                <BlogPage
                  setBlogLoading={setBlogLoading}
                  blogLoading={blogLoading}
                />
                <ViewFinancialIdea
                  setIdeaLoading={setIdeaLoading}
                  ideaLoading={ideaLoading}
                />
              </div>
            </>
          )}
        </div>
        <BackToTop />
      </div>
    </>
  );
};

export default Millionaires;
