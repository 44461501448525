import React, { useEffect, useState } from "react";
import BackToTop from "../Components/backToTop";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import SubHeadline from "../Components/sub-headline";
import End_of_Article from "../Components/ads/EndOfArticle";
import Parallax_Code from "../Components/ads/ParallaxCode";
import NoFoundData from "../Components/Common/noFoundData";
import Between_Content from "../Components/ads/BetweenContent";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SingleHeadline = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [headlineData, setHeadlineData] = useState({});
  const [adDisplayed, setAdDisplayed] = useState(false);
  const [headline, setHeadline] = useState();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");

  useEffect(() => {
    const getOneBlog = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${Base_Url}api/more-headline/${formattedTitle}`
        );
        setLoading(false);
        if (res) {
          setHeadlineData(res?.data?.data);
        }
      } catch (error) {
        setLoading(false);
        setNotFound(true);
      }
    };
    if (formattedTitle) {
      getOneBlog();
    }
    let clickCount = localStorage.getItem("clickCount") || "0";
    let click = Number(clickCount);
    if (click === 3) {
      setTimeout(() => {
        setAdDisplayed(true);
      }, 1000);
    }

    if (click >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  }, [formattedTitle]);
  useEffect(() => {
    const getAllHeadline = async () => {
      try {
        const response = await axios.get(`${Base_Url}api/more-headline`);
        if (response) {
          const filteredBlogs = response?.data?.data?.filter(
            (line) =>
              line?.title?.trim().toLowerCase() !==
              formattedTitle?.trim().toLowerCase()
          );
          const shuffledHeadline = shuffleArray(filteredBlogs)?.slice(0, 10);
          setHeadline(shuffledHeadline);
        }
      } catch (error) {
        console.error("Error fetching More Headline data:", error);
      }
    };
    getAllHeadline();
  }, []);
  const HeadlineWithParallax = ({ description }) => {
    const BETWEEN_CONTENT = `{{BETWEEN_CONTENT_AD}}`;
    const parts = description?.split(BETWEEN_CONTENT);
    return (
      <>
        {parts?.map((part, index) => (
          <div key={index}>
            <div dangerouslySetInnerHTML={{ __html: part }} />

            {index < parts?.length - 1 && <Between_Content />}
          </div>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="container max-w-7xl mx-auto px-4">
        {!notFound ? (
          <>
            {loading ? (
              <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
                <div className="loader2 my-5"></div>
              </div>
            ) : (
              <div className="">
                <div className="flex justify-center pt-5">
                  <div className="text-center mt-3 h-full ">
                    <h1 className="text-[#000] px-[20px] text-3xl leading-snug font-semibold max-[1024px]:text-center max-[1024px]:!leading-[60px] max-[1024px]:px-0 max-[768px]:text-2xl max-[768px]:!leading-normal">
                      {headlineData?.title}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-end">
                  {headlineData?.sourceBy && (
                    <p className="text-[14px] text-gray-500 mt-2 mr-1">
                      {headlineData?.sourceBy + " | "}
                    </p>
                  )}
                  {headlineData?.created_at && (
                    <p className="text-[14px] text-gray-500 mt-2">
                      {new Date(headlineData?.created_at).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  )}
                </div>
                <div className="flex justify-center lg:h-[550px] mb-5 w-full">
                  <div className="w-full">
                    <img
                      src={headlineData?.image}
                      className="h-full w-full object-cover"
                      alt={headlineData?.image}
                    />
                  </div>
                </div>
                <Parallax_Code />
                <div className="flex gap-5">
                  <div className="lg:w-[70%] w-full">
                    <HeadlineWithParallax
                      description={headlineData?.description}
                    />
                  </div>
                  <div className="lg:w-[30%] lg:block hidden">
                    <div className="border border-[#cacaca]">
                      <SubHeadline headlineData={headline} />
                    </div>
                    <End_of_Article />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <NoFoundData title="Ooops... No Headline found!" />
        )}
        <BackToTop />
      </div>
    </>
  );
};

export default SingleHeadline;
