import React, { useEffect, useState, memo } from "react";
import { topEarnerBlog } from "./data/data";
import Skeleton from "react-loading-skeleton";
import Card from "./Common/Card";

const TopEarner = memo(() => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = topEarnerBlog[0]?.image;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div>
      <h3
        className="py-3 text-[40px] border-t border-b border-[#C7CACC] text-center sm:mt-16 mt-8 sm:mb-12 mb-5 font-semibold"
        style={{
          fontFamily: "Playfair Display",
          fontSize: "clamp(1.5rem, 4vw, 3rem)",
        }}
      >
        The Top Earners
      </h3>

      {/* Main Image Section */}
      <div
        className="grid sm:grid-cols-2 items-center md:gap-10 gap-5 cursor-pointer"
        onClick={() => {
          const formattedTitle = topEarnerBlog[0]?.title.replace(/\s+/g, "-");
          window.location.href = `/top-earner/${formattedTitle}`;
        }}
      >
        <div className="md:pe-5 sm:h-full h-[300px]">
          {imageLoaded ? (
            <img
              src={topEarnerBlog[0]?.image}
              loading="lazy"
              alt="top earner"
              className="h-full w-full object-cover object-top"
            />
          ) : (
            <Skeleton className="bg-[#e0e0e0] opacity-[0.5] h-full w-full" />
          )}
        </div>
        <div className="md:flex items-center min-[1500px]:w-[80%] cursor-pointer">
          <div>
            <h2
              className="font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.6rem, 4vw, 2.5rem)",
              }}
            >
              {topEarnerBlog[0]?.title}
            </h2>
            <p
              className="md:mt-5 mt-3 lg:text-[18px] text-[15px]"
              dangerouslySetInnerHTML={{
                __html: topEarnerBlog[0]?.short_description,
              }}
            ></p>
            <div className="flex mt-2">
              {topEarnerBlog[0]?.sourceBy && (
                <p className="text-[14px] text-gray-500">
                  {topEarnerBlog[0]?.sourceBy + " | "}
                </p>
              )}
              {topEarnerBlog[0]?.created_at && (
                <p className="text-[14px] text-gray-500">
                  {new Date(topEarnerBlog[0]?.created_at).toLocaleDateString(
                    "en-US",
                    {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Other Earners Section */}
      <div className="mt-7">
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
          {topEarnerBlog?.slice(1)?.map((earner, index) => {
            const formattedTitle = earner?.title.replace(/\s+/g, "-");
            return <Card data={earner} formattedTitle={formattedTitle} />;
          })}
        </div>
      </div>
    </div>
  );
});

export default TopEarner;
