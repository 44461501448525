import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import BlogPage from "../Pages/Blog-page";
import Layout from "../Components/Layout";
import AboutUs from "../Pages/about-us";
import SingleViewIdea from "../Components/single-view-idea.js";
import ViewFinancialIdea from "../Components/view financial-idea.js";
import NotFound from "../Pages/NotFound";
import Disclaimer from "../Pages/disclaimer.js";
import Books from "../Pages/books.js";
import CelebrityDetails from "../Pages/celebrity-details.js";
import Celebrity from "../Pages/celebrity";
import CelebrityData from "../Pages/celebrity-data";
import SingleBlogDatails from "../Pages/single-blog-details.js";
import ViewPerfumeData from "../Pages/viewPerfumeData.js";
import MillionaireShopping from "../Pages/millionaireShopping.js";
import EarnerData from "../Pages/earner-single-data.js";
import SingleHeadline from "../Pages/single-headline.js";
import SuccessAllStep from "../Components/success-all-step.js";
import LifeStyleView from "../Pages/lifeStyle-view.js";
import LifeStyle from "../Pages/LifeStyle.js";
import TermsConditions from "../Pages/Terms-condition.js";
import PrivacyPolicy from "../Pages/Privacy-policy.js";
import SingleBlog from "../Pages/single-blog.js";
import Millionaires from "../Pages/millionaires.js";
import SingleMillionaire from "../Pages/Single-millionaire-detail.js";

const AllRoutes = () => {
  const [ideaLoading, setIdeaLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);

  return (
    <BrowserRouter>
      <React.Suspense>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              path="/"
              element={
                <Home
                  setBlogLoading={setBlogLoading}
                />
              }
            />
            <Route path="/millionaire" element={<Millionaires />} />
            <Route path="/millionaire/:name" element={<SingleMillionaire />} />
            <Route path="/top-earner/:title" element={<EarnerData />} />
            <Route path="/headline/:title" element={<SingleHeadline />} />
            <Route path="/celebrities" element={<Celebrity />} />
            <Route path="/celebrities-data/:title" element={<CelebrityData />} />
            <Route path="/celebrities-details/:title/:name" element={<CelebrityDetails />} />
            <Route path="/single-blog/:title" element={<SingleBlogDatails />} />
            <Route
              path="/blog"
              element={
                <BlogPage
                  blogLoading={blogLoading}
                  setBlogLoading={setBlogLoading}
                />
              }
            />
            <Route path="/blog/:title" element={<SingleBlog />} />
            <Route path="/success-step" element={<SuccessAllStep />} />
            <Route path="/life-style" element={<LifeStyle />} />
            <Route path="/view-life-style" element={<LifeStyleView />} />
            <Route
              path="/view-financial-idea"
              element={
                <ViewFinancialIdea
                  ideaLoading={ideaLoading}
                  setIdeaLoading={setIdeaLoading}
                />
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/books" element={<Books />} />
            <Route path="/financial-idea/:topic" element={<SingleViewIdea />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route
              path="/millionaire-shopping/:title"
              element={<MillionaireShopping />}
            />
            <Route path="/view-perfume-data/:title" element={<ViewPerfumeData />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AllRoutes;
