import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";
import Between_Content from "../Components/ads/BetweenContent";
import End_of_Article from "../Components/ads/EndOfArticle";
import Parallax_Code from "../Components/ads/ParallaxCode";

const MillionaireShopping = () => {
  const location = useLocation();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ")
  const [allShoppingData, setAllShoppingData] = useState([]);
  const [adDisplayed, setAdDisplayed] = useState(false);
  const refs = useRef([]);
  
  useEffect(() => {
    const getAllShoppingData = async () => {
      try {
        const response = await axios.get(`${Base_Url}api/life-style/shopping`);
        if (response) {
          const shopping = response?.data.data.filter(
            (x) => x?.type === "Shopping"
          );
          setAllShoppingData(shopping);
        }
      } catch (error) {
        console.log("Error fetching shopping data:", error);
      }
    };
    getAllShoppingData();
  }, [formattedTitle]);
  useEffect(() => {
    if (formattedTitle && allShoppingData?.length > 0) {
      const matchedIndex = allShoppingData.findIndex(
        (item) => item.title == formattedTitle
      );
  
      // If there is a match, scroll to that item
      if (matchedIndex !== -1 && refs.current[matchedIndex]) {
        refs.current[matchedIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [formattedTitle, allShoppingData]);

  const adsArray = [
    <Below_Featured_Images />,
    <Between_Content />,
    <End_of_Article />,
    <Parallax_Code />,
  ];

  useEffect(() => {
    let clickCount = localStorage.getItem("clickCount") || "0";
    let click = Number(clickCount);
    if (click === 3) {
      setTimeout(() => {
        setAdDisplayed(true);
      }, 1000);
    }

    if (click >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  }, []);

  return (
    <div>
      {/* <LeftSideAd />
      <RightSideAd /> */}
      {/* {adDisplayed && <GoogleAdsScript />} */}
      <div className="bg-white py-5 px-3">
        <div className="container max-w-7xl mx-auto">
          {allShoppingData?.map((item, index) => {
            const adComponent = adsArray[index % adsArray.length];
            return (
              <div ref={(el) => (refs.current[index] = el)} key={item.id}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center pt-20">
                  <div className="" key={index}>
                    <img src={item?.image} alt="Puffer Tote Bag" />
                  </div>

                  <div className="text-center">
                    <h2
                      className="font-bold mb-4"
                      style={{
                        fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                      }}
                    >
                      {item?.title}
                    </h2>
                    <p className="text-[18px] text-gray-600" dangerouslySetInnerHTML={{
                              __html: item?.description}}>
                    </p>
                  </div>
                </div>
                <div className="m-5">{adComponent}</div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-12">
                  {item?.subProducts?.map((blog, index) => (
                    <div className="" key={index}>
                      <img src={blog?.image} alt={blog.title} />
                      <p
                        className="text-center mt-1"
                        style={{
                          fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                        }}
                      >
                        {blog?.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default MillionaireShopping;
