import React, { useEffect, useState } from "react";
import { otherCelebrityBlogs } from "../Components/data/data";
import BackToTop from "../Components/backToTop";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";
import Between_Content from "../Components/ads/BetweenContent";
import End_of_Article from "../Components/ads/EndOfArticle";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const LifeStyle = () => {
  const [lifeStyleData, setLifestyleData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllLifeStyleData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_Url}api/life-style`);
        if (response) {
          setLoading(false);
          setLifestyleData(response?.data?.data);
        }
      } catch (error) {
        setLoading(false);
        console.log("Error fetching life style data:", error);
      }
    };
    getAllLifeStyleData();
  }, []);

  const handleCardClick = () => {
    let clickCount = localStorage.getItem("clickCount") || 0;
    let click = Number(clickCount);
    localStorage.setItem("clickCount", click + 1);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const destopImageArray = ["https://billionaireweb.s3.eu-north-1.amazonaws.com/web.webp", "https://billionaireweb.s3.eu-north-1.amazonaws.com/web-1.webp", "https://billionaireweb.s3.eu-north-1.amazonaws.com/trending.webp"];
  const tabletArray = ["https://billionaireweb.s3.eu-north-1.amazonaws.com/trending1.webp", "https://billionaireweb.s3.eu-north-1.amazonaws.com/tab.webp", "https://billionaireweb.s3.eu-north-1.amazonaws.com/tab-1.webp"];
  const mobileArray = ["https://billionaireweb.s3.eu-north-1.amazonaws.com/trending2.webp", "https://billionaireweb.s3.eu-north-1.amazonaws.com/mob.webp", "https://billionaireweb.s3.eu-north-1.amazonaws.com/mob-1.webp"];

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
          <div className="loader2 my-5"></div>
        </div>
      ) : (
        <div>
          <h3
            className="text-[40px] text-center mt-6 font-semibold"
            style={{
              fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
              fontFamily: "Playfair Display",
            }}
          >
            Most Stylish Millionaires Ever
          </h3>
          <div className="flex justify-center my-3">
            <img
              src="https://billionaireweb.s3.eu-north-1.amazonaws.com/Layer_1.webp"
              alt=""
              style={{ width: "clamp(150px, 50%, 250px)" }}
            />
          </div>
          <div className="relative w-full ">
            <Carousel
              additionalTransfrom={0}
              showDots
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              draggable={false}
              className="desktopSlider"
              containerClass=""
              focusOnSelect={false}
              infinite
              keyBoardControl
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              slidesToSlide={1}
            >
              {destopImageArray.map((item, index) => {
                return (
                  <div className="flex justify-center cursor-pointer" key={index} 
                  onClick={()=> window.location.href = "single-blog/47-Crazy-Celebrity-Outfits-We'll-Never-Forget"}
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
                );
              })}
            </Carousel>

            <Carousel
              additionalTransfrom={0}
              showDots
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              draggable={false}
              className="tabletSlider"
              containerClass=""
              focusOnSelect={false}
              infinite
              keyBoardControl
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              slidesToSlide={1}
            >
              {tabletArray.map((item, index) => {
                return (
                  <div className="flex justify-center cursor-pointer" key={index}
                  onClick={()=> window.location.href = "single-blog/47-Crazy-Celebrity-Outfits-We'll-Never-Forget"}
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
                );
              })}
            </Carousel>

            <Carousel
              additionalTransfrom={0}
              showDots
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              draggable={false}
              className="mobileSlider"
              containerClass=""
              focusOnSelect={false}
              infinite
              keyBoardControl
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={responsive}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              slidesToSlide={1}
            >
              {mobileArray.map((item, index) => {
                return (
                  <div className="flex justify-center cursor-pointer" key={index} 
                  onClick={()=> window.location.href = "single-blog/47-Crazy-Celebrity-Outfits-We'll-Never-Forget"}
                  >
                    <img
                      src={item}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
                );
              })}
            </Carousel>

            <div className="absolute bottom-5 left-5 md:bottom-10 md:left-20 bg-white text-black py-3 px-4 md:px-8 shadow-lg">
              <h2 className="font-bold text-lg md:text-2xl">Best Dress</h2>
            </div>
          </div>
          {/* <LongAd /> */}
          <Below_Featured_Images />
          <div className="container max-w-7xl mx-auto px-4">
            <div
              className="cursor-pointer"
              onClick={() => (window.location.href = `/view-life-style`)}
            >
              <h3
                className="lg:max-w-[50%] mx-auto text-[40px] text-center mt-10 mb-2 font-semibold"
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "clamp(1.5rem, 4vw, 2.9rem)",
                }}
              >
                Hair Trends that Must-Try According to Expert
              </h3>
              <p className="text-[16px] text-end pe-2 text-gray-600 mt-2">
                elle.com / 20 september,2024
              </p>

              <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/trendImg.webp" alt="" />
            </div>
            {/* <LongAdCard /> */}
            <Between_Content />
            <div className="">
              <h3
                className="py-3 border-t border-b border-[#C7CACC] text-[45px] text-center mt-10 mb-8 font-semibold"
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "clamp(1.5rem, 4vw, 3rem)",
                }}
              >
                Millionaires Fashion Trends
              </h3>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 md:gap-5 gap-3  mt-5">
                {lifeStyleData?.Shopping?.length > 0 &&
                  lifeStyleData?.Shopping?.map((data, index) => {
                    return (
                      <div
                        className="flex items-center max-[500px]:block cursor-pointer"
                        key={index}
                        onClick={() => {
                          handleCardClick();
                          const formattedTitle = data?.title?.replace(
                            /\s+/g,
                            "-"
                          );
                          window.location.href = `/millionaire-shopping/${formattedTitle}`;
                        }}
                      >
                        <div
                          className="h-auto max-[500px]:!w-full"
                          style={{ width: "200px" }}
                        >
                          <img
                            src={data?.image}
                            alt=""
                            className="h-full w-full"
                          />
                        </div>
                        <div className="sm:w-[calc(100%-230px)] ms-4 max-[500px]:ms-1">
                          <p className="pe-2 text-gray-600 mt-1 md:text-[17px] text-[12px]">
                            elle.com
                          </p>
                          <p className="pe-2 text-gray-600 md:text-[17px] text-[12px]">
                            20 sep, 2024
                          </p>
                          <p className=" font-medium md:text-[17px] text-[12px] mt-1 sm:mt-0   md:line-clamp-3 line-clamp-2">
                            {data?.title}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* <LongAdCard02 /> */}
              <End_of_Article />
              <div>
                <h3
                  className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
                  style={{
                    fontFamily: "Playfair Display",
                    fontSize: "clamp(1.5rem, 4vw, 3rem)",
                  }}
                >
                  Street Style
                </h3>
                <div
                  className="mt-4 lg:max-w-[70%] mx-auto"
                  style={{ fontFamily: "Playfair Display" }}
                >
                  {otherCelebrityBlogs
                    .slice(0, 2)
                    ?.filter(
                      (celebrities) => celebrities?.category === "life style"
                    )
                    ?.map((celebrity, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="cursor-pointer mt-10"
                          onClick={() => {
                            handleCardClick();
                            const formattedTitle = celebrity?.title?.replace(
                              /\s+/g,
                              "-"
                            );
                            window.location.href = `/single-blog/${formattedTitle}`;
                          }}
                        >
                          <img
                            src={celebrity.image}
                            alt=""
                            className="mx-auto"
                          />
                          <h3 className="md:text-[30px] text-[22px] text-center">
                            {celebrity.title}
                          </h3>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </div>
              {/* <AdResponsive06 /> */}
              <End_of_Article />
              <div>
                <h3
                  className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
                  style={{
                    fontFamily: "Playfair Display",
                    fontSize: "clamp(2rem, 4vw, 3rem)",
                  }}
                >
                  Perfumes & Fragrances
                </h3>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleCardClick();
                    const formattedTitle =
                      lifeStyleData?.Perfume[0]?.title?.replace(/\s+/g, "-");
                    window.location.href = `/view-perfume-data/${formattedTitle}`;
                  }}
                >
                  <p className="text-[16px] text-end pe-2 text-gray-600 mt-2">
                    elle.com / 20 september,2024
                  </p>
                  <img
                    src={lifeStyleData?.Perfume[0]?.image}
                    alt=""
                    className="mx-auto"
                  />

                  <h3
                    className="text-[30px] text-center lg:max-w-[70%] mt-2 mx-auto"
                    style={{
                      fontFamily: "Playfair Display",
                      fontSize: "clamp(16px, 4vw, 30px)",
                    }}
                  >
                    {lifeStyleData?.Perfume[0]?.title}
                  </h3>
                </div>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-20 mb-12">
                {lifeStyleData?.Perfume?.length > 1 &&
                  lifeStyleData?.Perfume?.slice(1)?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          handleCardClick();
                          const formattedTitle = data?.title?.replace(
                            /\s+/g,
                            "-"
                          );
                          window.location.href = `/view-perfume-data/${formattedTitle}`;
                        }}
                        className="cursor-pointer"
                      >
                        <div className="h-[230px] mx-auto lg:w-[100%] w-full">
                          <img
                            src={data?.image}
                            alt=""
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <p className="text-[16px] pe-2 text-gray-600 mt-1">
                          elle.com / 20 september,2024
                        </p>
                        <p className="mx-auto font-medium text-[17px] w-full mt-">
                          {data?.title}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
      <BackToTop />
    </div>
  );
};

export default LifeStyle;
