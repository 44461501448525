import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Base_Url from "../api/baseUrl";
import BackToTop from "../Components/backToTop";
import SubBlog from "../Components/HomeSubPage/Sub-blog";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";
import Parallax_Code from "../Components/ads/ParallaxCode";
import End_of_Article from "../Components/ads/EndOfArticle";
import NoFoundData from "../Components/Common/noFoundData";
import Between_Content from "../Components/ads/BetweenContent";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const SingleBlog = () => {
  const page = 0;
  const limit = 100;
  const location = useLocation();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState({});
  const [adDisplayed, setAdDisplayed] = useState(false);
  const [remainingItems, setRemainingItems] = useState([]);
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");

  useEffect(() => {
    const getOneBlog = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${Base_Url}api/blog/${formattedTitle}`);
        setBlog(res?.data?.data);
        localStorage.setItem("returningFromBlog", "true");
      } catch (error) {
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    if (formattedTitle) {
      getOneBlog();
    }

    const clickCount = Number(localStorage.getItem("clickCount") || "0");
    if (clickCount === 3) {
      setTimeout(() => {
        setAdDisplayed(true);
      }, 1000);
    }

    if (clickCount >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  }, [formattedTitle]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const allBlogData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`
        );
        const filteredBlogs = response?.data?.data?.filter(
          (blog) => blog?.slug !== formattedTitle
        );
        const shuffledBlogs = shuffleArray(filteredBlogs)?.slice(0, 10);
        setRemainingItems(shuffledBlogs);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      } finally {
        setLoading(false);
      }
    };

    allBlogData();
  }, [formattedTitle]);

  const formattedDate = useMemo(() => {
    return blog?.created_at
      ? new Date(blog?.created_at).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      : "";
  }, [blog]);

  const BlogWithParallax = ({ description }) => {
    const BETWEEN_CONTENT = `{{BETWEEN_CONTENT_AD}}`;
    const parts = description?.split(BETWEEN_CONTENT);
    return (
      <>
        {parts?.map((part, index) => (
          <div key={index}>
            <div dangerouslySetInnerHTML={{ __html: part }} />
  
            {index < parts?.length - 1 && (
                <Between_Content />
            )}
          </div>
        ))}
      </>
    );
  };
  return (
    <div className="container max-w-7xl mx-auto px-4">
      {!notFound ? (
        <>
          {loading ? (
            <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
              <div className="loader2 my-5"></div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center pt-5">
                <div className="text-center my-3 h-full">
                  <h1 className="text-[#000] px-[20px] text-3xl leading-snug font-semibold max-[1024px]:text-center max-[1024px]:!leading-[60px] max-[1024px]:px-0 max-[768px]:text-2xl max-[768px]:!leading-normal">
                    {blog?.title}
                  </h1>
                </div>
              </div>
              <Below_Featured_Images />
              <div className="flex justify-end">
                {blog?.sourceBy && (
                  <p className="text-[14px] text-gray-500 mt-2">
                    {blog?.sourceBy + " | "}
                  </p>
                )}
                {formattedDate && (
                  <p className="text-[14px] text-gray-500 mt-2">
                    {formattedDate}
                  </p>
                )}
              </div>
              <div className="flex justify-center lg:h-[550px] mb-5 w-full mx-auto">
                <img
                  src={blog?.image}
                  className="h-full w-full object-cover"
                  alt={blog?.title}
                  loading="lazy" 
                />
              </div>
              <div className="my-5">
                <Parallax_Code />
              </div>
              <div className="flex gap-5">
                <div className="lg:w-[70%] w-full">
                <BlogWithParallax description={blog?.description} />
                </div>
                <div className="lg:w-[30%] lg:block hidden">
                  <div className="border border-[#cacaca]">
                    <SubBlog blogData={remainingItems} title="Recent Blog" />
                  </div>
                  <End_of_Article />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <NoFoundData title="Ooops... No Blog found!" />
      )}
      <BackToTop />
    </div>
  );
};

export default SingleBlog;
