import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import { otherCelebrityBlogs } from "../Components/data/data";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";
import Between_Content from "../Components/ads/BetweenContent";
import End_of_Article from "../Components/ads/EndOfArticle";
import Parallax_Code from "../Components/ads/ParallaxCode";


const SingleBlogDatails = () => {
  const location = useLocation();
  const querySlug = decodeURIComponent(location.pathname.split("/").pop());
  const blogName = querySlug.replace(/-/g, " ");
  const [blogData, setBlogData] = useState({});
  const [adDisplayed, setAdDisplayed] = useState(false);
  const [loading, setLoading] = useState(false);
  const adsArray = [
    <Below_Featured_Images />,
    <Between_Content />,
    <End_of_Article />,
    <Parallax_Code />,
  ];
  useEffect(() => {
    const getAllItemsById = async () => {
      setLoading(true);
      try {
        if (blogName) {
          const specificTitles = [
            "Do you Want to know about latest street style",
            "The best Street style from new york fashion week spring",
            "The Richest Self Made Millionaires",
          ];

          const isSpecificTitle = specificTitles.includes(blogName);

          if (isSpecificTitle) {
            const filteredData = otherCelebrityBlogs?.find(
              (item) => item?.title === blogName
            );
            setBlogData(filteredData);
          } else {
            const response = await axios.get(
              `${Base_Url}api/category/${blogName}`
            );
            setBlogData(response?.data?.data);
            setLoading(false)
          }
        }
      } catch (error) {
        console.error("fetching error from blog details", error);
      } finally {
        setLoading(false);
      }
    };
    getAllItemsById();
  }, [blogName]);

  useEffect(() => {
    let clickCount = localStorage.getItem("clickCount") || "0";
    let click = Number(clickCount);
    if (click === 3) {
      setTimeout(() => {
        setAdDisplayed(true);
      }, 1000);
    }

    if (click >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  }, []);
  return (
    <>
      {/* <LeftSideAd />
      <RightSideAd />
      {adDisplayed && <GoogleAdsScriptCard />} */}
      <div className="bg-black text-white pb-[20px] min-h-screen">
        {loading ? (
          <div className="flex justify-center items-center md:min-h-screen min-h-[60vh]">
            <div className="loader1 my-5"></div>
          </div>
        ) : (
          <div className="container mx-auto px-5 lg:px-0">
            <h3
              className="text-center md:text-[38px] text-[26px] font-semibold border-b border-gray-500 pt-8 pb-5 lg:w-[80%] mx-auto"
              style={{ fontFamily: "math" }}
            >
              {blogData?.title}
            </h3>
            <p className="text-center mt-5 lg:max-w-[70%] mx-auto md:text-md text-sm">
              {blogData?.description}
            </p>

            <div className="mt-6 lg:max-w-[70%] mx-auto">
              {/* <LongAd /> */}
              <Between_Content />
              <div className="flex justify-end">
                {blogData?.sourceBy && (
                  <p className="text-[14px] md:text-[18px] mb-1 mt-10 mr-1">
                    {blogData?.sourceBy + " | "}
                  </p>
                )}
                <p className=" text-[14px] md:text-[18px] mb-1 mt-10">
                  {new Date(blogData?.created_at)?.toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
              </div>
              <div className="h-[300px] md:h-full mt-1">
                <img
                  src={blogData?.image}
                  alt={blogData?.title}
                  loading="lazy"
                  className="h-full object-cover w-[100%]"
                />
              </div>
              <p className="text-center mt-5 my-5 text-sm lg:text-md">
                {blogData?.description2}
              </p>
            </div>
            {/* <AdResponsive05 /> */}
            <Below_Featured_Images />
            <div className="md:px-2 lg:px-4 xl:max-w-[70%] mx-auto">
              {blogData?.categoriesBlogs?.length > 0 ? (
                blogData?.categoriesBlogs?.map((data, index) => {
                  const adComponent = adsArray[index % adsArray.length];
                  return (
                    <>
                      <div key={index} className="md:flex md:mt-20 mt-10">
                        <div className="h-[calc(100vh-10rem)] md:h-full relative md:unset md:flex items-center">
                          <div className="w-full h-full">
                            <img
                              src={data.image}
                              className="w-full h-full"
                              alt=""
                            />
                          </div>
                          <div
                            className="md:mt-0 mt-3 absolute md:relative p-4 md:bottom-4 bottom-0 md:left-4 text-white md:ms-6 w-full"
                            style={{
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            <h3
                              className="mb-1 text-[18px] lg:text-[24px]"
                              style={{ fontFamily: "math" }}
                            >
                              {data.title}
                            </h3>
                            <p className="mt-2 md:max-w-[26rem] text-sm">
                              {data.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      {index % 2 !== 0 && (
                        <div key={`ad-${index}`} className="my-10">
                          {adComponent}
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <p>No blog details available.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleBlogDatails;
