import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { topEarnerBlog } from "../Components/data/data";
import SubBlog from "../Components/HomeSubPage/Sub-blog";
import Base_Url from "../api/baseUrl";
import axios from "axios";
import Parallax_Code from "../Components/ads/ParallaxCode";
import Below_Featured_Images from "../Components/ads/BelowFeaturedImages";
import Between_Content from "../Components/ads/BetweenContent";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
const EarnerData = () => {
  const location = useLocation();
  const [earnData, setEarnData] = useState();
  const [remainingItems, setRemainingItems] = useState();
  const earnName = location.pathname.split("/").pop();
  useEffect(() => {
    if (earnName) {
      const filteredData = topEarnerBlog?.find(
        (item) => item?.title.replace(/\s+/g, "-") == earnName
      );
      setEarnData(filteredData);
    }
  }, [earnName, topEarnerBlog]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const allBlogData = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/get-blog?limit=100&page=1`
        );
        if (response) {
          const shuffledData = shuffleArray([...response?.data?.data]);
          const randomRecords = shuffledData.slice(0, 15);
          setRemainingItems(randomRecords);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    allBlogData();
  }, []);
  const EarnWithParallax = ({ description }) => {
    const BETWEEN_CONTENT = `{{BETWEEN_CONTENT_AD}}`;
    const parts = description?.split(BETWEEN_CONTENT);
    return (
      <>
        {parts?.map((part, index) => (
          <div key={index}>
            <div dangerouslySetInnerHTML={{ __html: part }} />
  
            {index < parts?.length - 1 && (
                <Between_Content />
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="mt-10 container max-w-7xl mx-auto px-4">
      <h3
        className="text-[40px] text-center font-semibold"
        style={{
          fontFamily: "Playfair Display",
          fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
        }}
      >
        {earnData?.title}
      </h3>
      <div className="flex justify-center my-3">
        <img
          src="https://billionaireweb.s3.eu-north-1.amazonaws.com/Layer_1.webp"
          alt=""
          style={{ width: "clamp(150px, 50%, 250px)" }}
        />
      </div>
      <div className="sm:h-[550px] mt-4">
        <img
          src={earnData?.image}
          alt=""
          className="h-full w-full object-cover object-top"
        />
      </div>
      <Parallax_Code />
      <div className="flex gap-5">
        <div className="lg:w-[70%]">
          <EarnWithParallax description={earnData?.description} />
        </div>

        <div className="lg:w-[30%] h-full lg:block hidden">
          <div className="border border-[#cacaca] my-5">
            <SubBlog blogData={remainingItems} title="Recent Blog" />
            <Below_Featured_Images />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarnerData;
