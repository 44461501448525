import React, { useEffect, useState, useMemo, Suspense } from "react";
import BackToTop from "../Components/backToTop";
import Base_Url from "../api/baseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import CelebrityCard from "../Components/Common/celebrity-card";

const CelebrityEvents = React.lazy(() =>
  import("../Components/CelebrityEvents")
);
const Below_Featured_Images = React.lazy(() =>
  import("../Components/ads/BelowFeaturedImages")
);
const BetweenContent = React.lazy(() =>
  import("../Components/ads/BetweenContent")
);
const EndOfArticle = React.lazy(() =>
  import("../Components/ads/EndOfArticle")
);

const Celebrity = () => {
  const [celebrityData, setCelebrityData] = useState();
  const [celebrity, setCelebrity] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCelebrityData = async () => {
      setLoading(true);
      try {
        const [categoryResponse, celebrityResponse] = await Promise.all([
          axios.get(`${Base_Url}api/get-category`),
          axios.get(`${Base_Url}api/get-celebrity?page=1&limit=50`),
        ]);
        setCelebrityData(categoryResponse?.data?.data);
        setCelebrity(celebrityResponse?.data?.data);
      } catch (error) {
        console.error("Error fetching celebrity data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCelebrityData();
  }, []);

  const handleCardClick = debounce((formattedTitle) => {
    let clickCount = localStorage.getItem("clickCount") || 0;
    localStorage.setItem("clickCount", Number(clickCount) + 1);
    navigate(`/celebrities-data/${formattedTitle}`);
  }, 300);

  const celebrityList = useMemo(() => {
    return celebrity?.map((data, index) => (
      <React.Fragment key={index}>
        <CelebrityCard data={data} handleCardClick={handleCardClick} />
        {index === 3 && (
          <div className="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1 mt-4">
            <BetweenContent />
          </div>
        )}
        {index === 7 && (
          <div className="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1 mt-4">
            <EndOfArticle />
          </div>
        )}
      </React.Fragment>
    ));
  }, [celebrity]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center md:min-h-screen min-h-[60vh]">
          <div className="loader2 my-5"></div>
        </div>
      ) : (
        <div className="px-4">
          <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
            <CelebrityEvents celebrityData={celebrityData} />
            <div className="my-10">
              <Below_Featured_Images />
            </div>
            <div>
              <div className="">
                <h3
                  className="text-center md:text-[42px] text-[35px] font-semibold border-b border-gray-500"
                  style={{ fontFamily: "math" }}
                >
                  Celebrity Insider
                </h3>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 mt-3 grid-cols-1 gap-4">
                {celebrityList}
              </div>
            </div>
          </div>
          <BackToTop />
        </div>
      )}
    </>
  );
};

export default Celebrity;
