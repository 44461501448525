import React, {
  useEffect,
  useState,
  lazy,
  Suspense,
  startTransition,
} from "react";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import BackToTop from "../Components/backToTop";
import LazyLoad from "react-lazyload";
const Banner = lazy(() => import("../Components/HomeSubPage/Banner"));
const Club = lazy(() => import("../Components/HomeSubPage/Club"));
const BlogPost = lazy(() => import("../Components/HomeSubPage/Blog-Post"));
const TopEarner = lazy(() => import("../Components/Top-earner"));
const CelebrityEvents = lazy(() => import("./Celebrity-events"));
const MoreHeadline = lazy(() =>
  import("../Components/HomeSubPage/moreHeadline")
);

const FAQ = lazy(() => import("../Components/HomeSubPage/FAQ"));
const SubBlog = lazy(() => import("../Components/HomeSubPage/Sub-blog"));
const Between_Content = lazy(() => import("../Components/ads/BetweenContent"));
const Below_Featured_Images = lazy(() =>
  import("../Components/ads/BelowFeaturedImages")
);
const End_of_Article = lazy(() => import("../Components/ads/EndOfArticle"));
const Parallax_Code = lazy(() => import("../Components/ads/ParallaxCode"));

const FinanceSteps = lazy(() =>
  import("../Components/HomeSubPage/FinanceSteps")
);

const shuffleArray = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const Home = ({ setBlogLoading }) => {
  const [remainingItems, setRemainingItems] = useState([]);
  const [sliceData, setSliceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const page = 0;
  const limit = 100;

  useEffect(() => {
    const allBlogData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`
        );
        setLoading(false);
        if (response && response.data) {
          const shuffledData = shuffleArray([...response.data.data]);

          const firstThreeItems = shuffledData.slice(0, 6);
          setSliceData(firstThreeItems);

          startTransition(() => {
            const remainingItems = shuffledData.slice(6, 21);
            setRemainingItems(remainingItems);
          });
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };

    allBlogData();
  }, []);

  return (
    <Suspense>
      <div className="min-h-[100vh]">
        <Banner />
        <div className="container mt-52 lg:flex mx-auto max-w-[1700px] gap-4 px-7">
          <div className="lg:w-[75%]">
            <LazyLoad height={200} offset={100}>
              <TopEarner />
            </LazyLoad>

            <div className="my-5">
              <Between_Content />
            </div>
            <LazyLoad height={200} offset={100}>
              <BlogPost
                blogData={sliceData}
                loading={loading}
                setBlogLoading={setBlogLoading}
              />
            </LazyLoad>
          </div>

          <div className="lg:w-[25%] h-full lg:block hidden">
            <Below_Featured_Images />
            <div className="border border-[#cacaca] my-10">
              <LazyLoad height={200} offset={100}>
                <SubBlog blogData={remainingItems} title="Recent Blog" />
              </LazyLoad>
            </div>
          </div>
        </div>
        <Parallax_Code />
        <div className="container mx-auto max-w-[1700px] px-7">
          <LazyLoad height={200} offset={100}>
            <CelebrityEvents />
          </LazyLoad>
        </div>
        <div className="my-5">
          <Between_Content />
        </div>
        <LazyLoad height={200} offset={100}>
          <MoreHeadline />
        </LazyLoad>
        <End_of_Article />
        <LazyLoad height={200} offset={100}>
          <Club />
        </LazyLoad>
        <Below_Featured_Images />

        <div className="container lg:flex mx-auto max-w-[1700px] gap-4 px-7">
          <div className="lg:w-[75%]">
            <LazyLoad height={200} offset={100}>
              <FAQ />
            </LazyLoad>
          </div>
          <div className="lg:w-[25%] my-14 lg:block hidden">
            <div className="shadow-2xl rounded-lg border border-[#cacaca] my-16">
              <LazyLoad height={200} offset={100}>
                <FinanceSteps />
              </LazyLoad>
            </div>
          </div>
        </div>

        <BackToTop />
      </div>
    </Suspense>
  );
};

export default Home;
