import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import Between_Content from "../Components/ads/BetweenContent";
import End_of_Article from "../Components/ads/EndOfArticle";
import CelebrityCard from "../Components/Common/celebrity-card";
import BackToTop from "../Components/backToTop";

const CelebrityData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const querySlug = location.pathname.split("/").pop();
  const formattedTitle = querySlug.replace(/-/g, " ");

  const [celebrityData, setCelebrityData] = useState();
  const [celebrities, setCelebrities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [adDisplayed, setAdDisplayed] = useState(false);

  useEffect(() => {
    const fetchCelebrityData = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/celebrity/${formattedTitle}`
        );
        setCelebrityData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching celebrity data:", error);
      }
    };
    fetchCelebrityData();
  }, [formattedTitle]);

  useEffect(() => {
    const fetchOtherCelebrityData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-celebrity?page=1&limit=50`
        );
        setCelebrities(response?.data?.data);
      } catch (error) {
        console.error("Error fetching other celebrities:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOtherCelebrityData();
  }, []);
  useEffect(() => {
    let clickCount = localStorage.getItem("clickCount") || "0";
    let click = Number(clickCount);
    if (click === 3) {
      setTimeout(() => {
        setAdDisplayed(true);
      }, 1000);
    }

    if (click >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  }, []);
  const handleCardClick = useCallback(
    (newFormattedTitle) => {
      let clickCount = localStorage.getItem("clickCount") || 0;
      localStorage.setItem("clickCount", Number(clickCount) + 1);
      navigate(`/celebrities-data/${newFormattedTitle}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [navigate]
  );

  const filteredCelebrities = useMemo(() => {
    return celebrities.filter((cel) => cel.title !== formattedTitle);
  }, [celebrities, formattedTitle]);

  return (
    <div className="px-4">
      {loading ? (
        <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
          <div className="loader2 my-5"></div>
        </div>
      ) : (
        <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
          <h1 className="flex justify-center text-[#000] md:text-[33px] font-semibold text-[22px] text-center md:mb-5 mb-3">
            {celebrityData?.title}
          </h1>
          <table className="min-w-full border-2 border-gray-400 md:table hidden">
            <thead className="table-header-group">
              <tr className="border-2 border-black md:table-row">
                <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                  ID
                </th>
                <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                  Image
                </th>
                <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                  Name
                </th>
                <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                  Age
                </th>
                <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium md:text-[18px] sm:text-[16px] text-[11px]">
                  Net Worth
                </th>
              </tr>
            </thead>
            <tbody className="table-row-group">
              {celebrityData?.blogDetails?.map((item, index) => (
                <tr
                  key={item?.id}
                  className="bg-white border-b-2 border-gray-400 cursor-pointer"
                  onClick={() => {
                    const Title = item?.name?.replace(/\s+/g, "-");
                    window.location.href = `/celebrities-details/${querySlug}/${Title}`;
                  }}
                >
                  <td className="md:p-3 px-1 py-2 text-[#F06939] font-bold md:text-[20px] text-[13px]">
                    #{index + 1}
                  </td>
                  <td className="md:p-2 md:px-3 px-1 py-2 text-gray-700">
                    <img
                      src={item?.image}
                      alt=""
                      className="h-20 w-28 object-cover object-top"
                      loading="lazy"
                    />
                  </td>
                  <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                    {item.name}
                  </td>
                  <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                    {item.age}
                  </td>
                  <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                    {item.net_worth}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Between_Content />
          <div className="grid lg:grid-cols-3 min-[500px]:grid-cols-2 grid-cols-1 md:mt-16 min-[500px]:mt-10 mt-3 sm:gap-9 gap-7">
            {filteredCelebrities.map((celebrity, index) => (
              <React.Fragment key={index}>
                <CelebrityCard
                  data={celebrity}
                  handleCardClick={handleCardClick}
                />
                {index === 5 && (
                  <div className="lg:col-span-3 sm:col-span-2 col-span-1 mt-4">
                    <End_of_Article />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      <BackToTop />
    </div>
  );
};

export default CelebrityData;
