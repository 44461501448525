import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import ReactPaginate from "react-paginate";
import Modal from "../Components/Modal";
import { useNavigate } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import Between_Content from "../Components/ads/BetweenContent";
import Blog from "../Components/Common/blog";
// import LongAdCard from "../Components/ads/LongAdCard";

const BlogPage = ({ setBlogLoading, blogLoading }) => {
  const recentBlogRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 6;
  const [blogData, setBlogData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isPaginationClicked, setIsPaginationClicked] = useState(false);

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 640);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    const fetchBlogData = async () => {
      setBlogLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`
        );
        setBlogLoading(false);
        setBlogData(response?.data?.data);
        setPageCount(response?.data?.totalPage);
      } catch (error) {
        setBlogLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, [page, setBlogLoading]);

  useEffect(() => {
    const handleReturn = () => {
      const isReturning = localStorage.getItem("returningFromBlog");
      if (isReturning === "true") {
        localStorage.setItem("returningFromBlog", "false");
        setTimeout(() => {
          setIsModalOpen(true);
        }, 1500);
      }
    };
    handleReturn();
  }, [navigate]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const reloadAds = () => {
  //   if (window.googletag && window.googletag.cmd) {
  //     window.googletag.cmd.push(function () {
  //       window.googletag.display("div-gpt-ad-1726468021688-0");
  //     });
  //   }
  //   if (window.googletag && window.googletag.pubads) {
  //     try {
  //       window.googletag.pubads().refresh();
  //     } catch (error) {
  //       console.error("Failed to refresh ads:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   reloadAds();
  // }, [page]);

  useEffect(() => {
    if (isPaginationClicked && recentBlogRef.current) {
      const sectionTop =
        recentBlogRef.current.getBoundingClientRect().top +
        window.pageYOffset -
        100;

      window.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });

      setIsPaginationClicked(false);
    }
  }, [isPaginationClicked]);
  return (
    <div className="container max-w-7xl mx-auto px-4">
      {!blogLoading && (
        <div className="mx-auto pt-[3rem] mb-[20px] max-[768px]:pt-[2rem]">
          <div className="flex justify-center text-center">
            <div ref={recentBlogRef} className="w-[65%] max-[1024px]:w-[90%]">
              <h1
                className="text-[#000] font-bold md:text-[40px] text-[30px] mb-0"
                style={{ fontFamily: "Playfair Display" }}
              >
                Recent Blog Post
              </h1>
            </div>
          </div>
          <div className="blog-div mt-[0.5rem] max-[768px]:mt-0">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 lg:px-0">
              {blogData?.map((blog, index) => (
                <React.Fragment key={index}>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-4 h-full"
                    key={index}
                    onClick={() => {
                      let clickCount = localStorage.getItem("clickCount") || 0;
                      let click = Number(clickCount);
                      localStorage.setItem("clickCount", click + 1);
                    }}
                  >
                     <Blog data={blog}/>
                  </div>
                  {index === 2 && (
                    <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                      {/* <LongAdCard /> */}
                      <Between_Content />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
      {!blogLoading && blogData?.length > 0 && (
        <div className="flex justify-center mt-4 my-3 max-[768px]:mt-0">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={isSmallScreen ? 1 : 2}
            pageRangeDisplayed={isSmallScreen ? 1 : 3}
            onPageChange={(e) => {
              setPage(e.selected);
              setIsPaginationClicked(true);
            }}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={page}
          />
          <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
      )}
      {/* <AdResponsive02 /> */}
      <BackToTop />
    </div>
  );
};

export default BlogPage;
