import React, { useState, useEffect, useCallback, memo } from "react";
import Skeleton from "react-loading-skeleton";

const SubBlog = memo(({ blogData, title }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleBlogClick = useCallback((formattedTitle) => {
    window.location.href = `/blog/${formattedTitle}`;
  }, []);

  return (
    <div className="max-[1100px]:px-3 p-4 h-full">
      <div className="flex">
        <h3
          className="font-semibold text-[28px] border-b border-[#000]"
          style={{ fontFamily: "Playfair Display" }}
        >
          {title}
        </h3>
      </div>
      {blogData?.map((blog, index) => {
        const formattedTitle = blog?.title?.replace(/\s+/g, "-");

        return (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => handleBlogClick(formattedTitle)}
          >
            <div className="flex items-center max-[1100px]:mt-4 mt-5 gap-4">
              <div className="h-28 w-36">
                {imageLoaded ? (
                  <img
                    src={blog?.image}
                    alt={blog?.title}
                    className="h-full w-full object-cover"
                    loading="lazy"
                  />
                ) : (
                  <Skeleton
                    height="100%"
                    width="100%"
                    style={{
                      backgroundColor: "#e0e0e0",
                      opacity: 0.6,
                    }}
                  />
                )}
              </div>
              <div className="w-52">
                {blog?.created_at && (
                  <p className="text-[14px] text-gray-500 max-[1100px]:text-[12px]">
                    {new Date(blog?.created_at).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                )}
                <h4 className="line-clamp-3 tracking-[0.3px] font-medium hover:text-blue-800 text-[16px] max-[1100px]:text-[14px]">
                  {blog?.title}
                </h4>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default SubBlog;
